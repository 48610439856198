import React, { Component } from 'react';

const IconFont = Loader.loadBaseComponent('IconFont');
const FullScreenLayout = Loader.loadBaseComponent('FullScreenLayout');

class CloudTools extends Component {
  render() {
    const { mapReset, fullDom, fullScreenChange } = this.props;
    return (
      <div className="cloud-tools">
        <div className="tools-button" onClick={() => mapReset()}>
          <IconFont type="icon-S_Bar_Reduction" />
          复位
        </div>
        <FullScreenLayout className="tools-button" getContainer={() => fullDom} fullScreenChange={fullScreenChange}>
          {fullStatus => (
            <React.Fragment>
              <IconFont title={!fullStatus ? '全屏' : '窗口'} type={!fullStatus ? 'icon-S_View_FullScreen' : 'icon-S_View_ExitFullScreen'} theme="outlined" />
              {!fullStatus ? '全屏' : '窗口'}
            </React.Fragment>
          )}
        </FullScreenLayout>
      </div>
    );
  }
}
export default CloudTools;
