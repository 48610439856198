(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("moment"), require("antd"), require("_"), require("ReactRouterDOM"), require("mobxReact"));
	else if(typeof define === 'function' && define.amd)
		define("cloudCommunityOverview", ["React", "moment", "antd", "_", "ReactRouterDOM", "mobxReact"], factory);
	else if(typeof exports === 'object')
		exports["cloudCommunityOverview"] = factory(require("React"), require("moment"), require("antd"), require("_"), require("ReactRouterDOM"), require("mobxReact"));
	else
		root["cloudCommunityOverview"] = factory(root["React"], root["moment"], root["antd"], root["_"], root["ReactRouterDOM"], root["mobxReact"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 