import React from 'react';

const IconFont = Loader.loadBaseComponent('IconFont');

export default ({ icon = 'icon-S_Tree_Community', total = 0, today = 0 }) => (
  <div className="cloud-header">
    <IconFont type={icon} className="cloud-icon" />
    <div className="cloud-info">
      <div className="info-box">
        资源总数
        <div className="info-total">{total ? (+total).toLocaleString() : 0}</div>
      </div>
      <div className="info-box">
        今日新增
        <div className="info-total">{today ? (+today).toLocaleString() : 0}</div>
      </div>
    </div>
  </div>
);
