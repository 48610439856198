import React from "react";
import CloudTabsHeader from "./cloudHeader";
import moment from 'moment';

import "../../style/cloudTabs.less";

const LeftRightCard = Loader.loadBusinessComponent("Card", "LeftRightCard");
const CloudTabType = [{ value: 0, label: "人脸" }, { value: 1, label: "人体" }, { value: 2, label: "车辆" }, { value: 3, label: "门禁" }];

@Decorator.withTab
class CloudTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      data: [],
      total: 0,
    };
    this.timer = null;
    this.isForcusTab = true;
  }

  /**
   * @desc 定时调用接口刷新右侧列表
   */
  setTimeQueryList = active => {
    let villageIds = this.props.getVillageInfo();
    // 未分配小区时不调用接口（不然接口报错）
    if (!villageIds.length) {
      return Promise.resolve();
    }
    const option = {
      // 总量
      villageIds,
      days: -1
    };
    const optionToday = {
      // 当天
      villageIds,
      days: 1
    };
    const optionAcc = {
      villageIds,
      offset: 0,
      limit: 6
    };
    let queryList;
    switch (active) {
      case 0:
        queryList = [
          Utils.catchPromise(Service.community.queryFaces(optionAcc)),
          Utils.catchPromise(Service.community.countFaces(option)),
          Utils.catchPromise(Service.community.countFaces(optionToday))
        ];
        break;
      case 1:
        queryList = [
          Utils.catchPromise(Service.community.queryBodies(optionAcc)),
          Utils.catchPromise(Service.community.countBodies(option)),
          Utils.catchPromise(Service.community.countBodies(optionToday))
        ];
        break;
      case 2:
        queryList = [Service.community.queryCaptureVehiclesRecords(optionAcc)];
        break;
      case 3:
        queryList = [
          Utils.catchPromise(Service.community.queryAccessRecordsForCommunity(optionAcc)),
          Utils.catchPromise(Service.community.countAccessRecords({ villageIds })),
          Utils.catchPromise(Service.community.countAccessRecords({ villageIds, startTime: moment(moment().format('YYYY MM DD')).valueOf() }))
        ];
        break;
      default:
        break;
    }

    return Promise.all(queryList).then(res => {
      let list = [],
        tabsTotal = { total: 0, totalToday: 0 };
      if (active === 0 || active === 1) {
        const [listRes = {}, totalRes = {}, todayRes = {}] = res;
        list = Array.isArray(listRes.data) ? listRes.data : [];
        tabsTotal.total = totalRes.count || 0;
        tabsTotal.totalToday = todayRes.count || 0;
      }

      if (active === 2) {
        const [dataList] = res;
        list = dataList.list || [];
        tabsTotal.total = dataList.totalDateNum || 0;
        tabsTotal.totalToday = dataList.currentDateNum || 0;
      }

      if (active === 3) {
        const [listRes = {}, totalRes = {}, todayRes = {}] = res;
        list = listRes.data.list || [];
        tabsTotal.total = totalRes.count || 0;
        tabsTotal.totalToday = todayRes.count || 0;
      }

      this.setState({ data: list, total: tabsTotal });
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.storeId !== BaseStore.tab.currentId) {
      clearTimeout(this.timer);
      this.isForcusTab = false;
    } else {
      if (!this.isForcusTab) {
        this.isForcusTab = true;
        clearTimeout(this.timer);
        this.setInter();
      }
    }
  }

  componentDidMount() {
    this.setInter();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  setInter = (noloop) => {
    this.requestAction(noloop);
  };

  requestAction = (loop = true) => {
    if (!this.isForcusTab) {
      clearTimeout(this.timer);
      return;
    }
    const { active } = this.state;
    // const { setTimeQueryList } = this.props;
    if(!loop){
      return this.setTimeQueryList(active)
    }else{
      return this.setTimeQueryList(active).finally(() => {
        clearTimeout(this.timer);
        if (this.isForcusTab) {
          this.timer = setTimeout(() => {
            this.requestAction();
          }, 3000);
        }
      });
    }
  };

  getIcon = type => {
    let icon = "";
    switch (type) {
      case 0:
        icon = "icon-M_AID_Face";
        break;
      case 1:
        icon = "icon-L_AID_Body";
        break;
      case 2:
        icon = "icon-M_Files_CarAlarm";
        break;
      case 3:
        icon = "icon-S_Point_Door";
        break;
      default:
        break;
    }
    return icon;
  };

  handleTabs = async active => {
    await this.setState({ active });
    clearTimeout(this.timer);
    this.setInter(true);
  };

  cardType = type => {
    let imageType = "face";
    switch (type) {
      case 0:
        imageType = "face";
        break;
      case 1:
        imageType = "face";
        break;
      case 2:
        imageType = "car";
        break;
      case 3:
        imageType = "door";
        break;
      default:
        break;
    }
    return imageType;
  };

  FormatData = data => {
    const { active } = this.state;
    let param = {};
    switch (active) {
      case 0:
        param = {
          url: data.faceUrl,
          time: data.captureTime,
          address: data.deviceName,
          name: data.name ? data.name : true,
          aid: data.name ? undefined : '未登记人员',
          deviceName: data.presentAddress,
        };
        break;
      case 1:
        param = {
          url: data.bodyUrl,
          time: data.captureTime,
          address: data.deviceName,
        };
        break;
      case 2:
        param = {
          temporary: data.ownerName ? false :true,
          name: data.ownerName,
          url: data.vehicleUrl,
          plate: data.vehiclePlateNum ? data.vehiclePlateNum : '-',
          time: data.captureTime,
          address: data.deviceName,
        };
        break;
      case 3:
        param = {
          name: data.personName,
          controlType: Dict.getLabel("openType", data.accessType),
          url: data.pictureUrl,
          time: data.captureTime,
          address: data.address,
        };
        break;
      default:
        break;
    }
    return param;
  };

  render() {
    const { active } = this.state;
    // const { data = [], total = {} } = this.props;
    const { data = [], total = {} } = this.state;
    return (
      <div className="cloud-tabs-view">
        <div className={`view-header active-${active}`}>
          {CloudTabType.map(v => (
            <div className={`header-tab ${active === v.value ? "active" : ""}`} onClick={this.handleTabs.bind(this, v.value)}>
              {v.label}
            </div>
          ))}
        </div>
        <div className="view-content">
          <CloudTabsHeader
            icon={this.getIcon(active)}
            total={total.total ? total.total.toLocaleString("en-US") : 0}
            today={total.totalToday ? total.totalToday.toLocaleString("en-US") : 0}
          />
          <div className="card-box">
            {data.map(v => (
              <LeftRightCard {...this.FormatData(v)} imageType={this.cardType(active)} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CloudTabs;
