import React from 'react';
import { Select } from 'antd';

const IconFont = Loader.loadBaseComponent('IconFont');
const Option = Select.Option;

class CloudSelect extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }
  // 类别筛选
  handleTypeChange = value => {
    const { clickCommunity, data } = this.props;
    if (value === 'all') {
      value = data;
    }
    clickCommunity && clickCommunity(value);
  };
  goTo3DMap = (valigeInfo) => {
    if(!!valigeInfo.swBuildingUrl) {
      const token = Utils.getCache('token', 'session');
      const url = `${window.BSConfig.map3dUrl}?communityId=${valigeInfo.id}&token=${token}`;
      window.open(url);
    }
  };
  render() {
    let { data = [], value } = this.props;
    return (
      <div ref={this.selectRef}>
        <Select
          dropdownClassName="cloud_filter_select_type_downwrap"
          className="cloud_filter_type_select"
          getPopupContainer={() => this.selectRef.current}
          style={{ width: 300 }}
          onChange={this.handleTypeChange}
          defaultValue='all'
          value={value}
          size="default"
          suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}>
          <Option value='all'>
            <IconFont type="icon-S_Tree_Community" />
            全部
          </Option>
          {data.map(v => (
            <Option value={v.id}>
              <IconFont type="icon-S_Tree_Community" />
              {v.villageName}
             { v.swBuildingUrl && <IconFont className="view-3d-icon" type="icon-S_Photo_View3D" onClick={() => this.goTo3DMap(v)}/> }
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default CloudSelect;
