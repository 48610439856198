import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import CloudTabs from './components/cloudTabs';
import Title from './components/title';
import Select from './components/select';
import LeftEcharts from './components/leftEcharts';
import CloudTools from './components/cloudTools';
import BottomEcharts from './components/bottomEchart';

import './style/cloudTheme/index.less';
import './style/index.less';

const DEVICE = ['100604', '100603', '100607', '100602'],
  ROADGATE = [],
  ACCESS = ['103501', '103502'];

const CommunityMap = Loader.loadBusinessComponent('MapComponent', 'CommunityMap');
const ImageView = Loader.loadBaseComponent('ImageView');
const IconFont = Loader.loadBaseComponent('IconFont');
const { InfoWindow } = LMap;

@withRouter
@Decorator.withEntryLog() //日志
@observer
class CloudCommunityOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleCount: {}, // 社区人员统计
      deviceCount: {}, // 社区设备统计
      housCount: {}, // 小区房屋统计
      FrequenCount: {}, // 人员出入规律
      numCount: {}, // 人流量分布规律
      loading: true,
      tabsList: [], // 右侧tabs列表
      tabsTotal: {}, // tabs统计
      list: [], // 小区列表
      chosevillage: 'all', // 选择的小区
      fullScreenState: false, // 全屏状态
      deviceList: [], // 设备列表
      infoWindowVisible: false, // 地图弹窗
      infoWindowPeopleCount: {},
      infoWindowHousCount: {},
    };
    this.cloudRef = React.createRef();
    this.mapRef = React.createRef();
    this.infoWindow = null;
    this.cloudMap = null;
    this.init();
  }

  componentWillUnmount() {
    this.infoWindow = null;
    this.cloudMap = null;
  }

  init = async () => {
    const list = await this.getCommunityList();
    this.initNumbers(list);
    const ids = list.map(v => v.id);
    const deviceList = [];
    if (ids.length) {
      const deviceRes = await Service.community.assignedDevice({ ids });
      deviceRes &&
        deviceRes.data.forEach((v, index) => {
          v.devices.forEach(item => {
            deviceList.push(Object.assign({}, item, { villageId: list[index].id }));
          });
        });
    }
    this.setState({
      loading: false,
      list,
      deviceList
    });
  };

  infoWindowInit = infoWindow => {
    this.infoWindow = infoWindow;
  };

  queryVillage = parms => {
    let arr = [];
    if (Array.isArray(parms)) {
      parms.forEach(v => arr.push(v.id));
    } else {
      arr.push(parms);
    }
    return arr;
  };

  initNumbers = async (parms = []) => {
    const { list } = this.state;
    let villageIds = this.queryVillage(parms);
    // 未分配小区时不调用接口（不然接口报错）
    if (!villageIds.length) {
      return;
    }
    let placeIds = [];
    if (Array.isArray(parms)) {
      placeIds = parms.filter(v => v.placeId).map(v => v.placeId);
    } else {
      const choseList = list.find(v => v.id === parms);
      choseList.placeId && placeIds.push(choseList.placeId);
    }
    const option = { villageIds };
    const optionFreCMT = { placeIds: placeIds, timeType: 3 };
    const optionCMT = { placeIds: placeIds, timeType: 3 };
    /** 社区人员统计 */
    const countVillagePeople = Service.community.countVillagePeople(option);
    /** 社区安防设施 */
    const countVillageDevice = Service.community.countVillageDevice(option);
    /** 社区车辆统计 */
    const countVillageVehicles = Service.community.countVillageVehicles(option);
    /**小区房屋统计 */
    const housingStatistics = Service.community.housingStatistics(option);
    /** 人员出入规律 */
    const countPersonFrequencyToCMT = placeIds.length ? Service.place.countPersonFrequencyToCMT(optionFreCMT) : new Promise((resolve, reject) => resolve());
    /** 人流量分布规律 */
    const countPersonNumToCMT = placeIds.length ? Service.place.countPersonNumToCMT(optionCMT) : new Promise((resolve, reject) => resolve());
    const arrRes = await Promise.all([
      Utils.catchPromise(countVillagePeople),
      Utils.catchPromise(countVillageDevice),
      Utils.catchPromise(countVillageVehicles),
      Utils.catchPromise(housingStatistics),
      Utils.catchPromise(countPersonFrequencyToCMT),
      Utils.catchPromise(countPersonNumToCMT)
    ]);
    const [peopleRes, deviceRes, carRes, houseRes, freRes, numRes] = arrRes;
    let peopleCount = {
        disappearCount: 0,
        frequentlyCount: 0,
        occasionallyCount: 0,
        permanentCount: 0
      },
      deviceCount = {
        camera: 0, // 摄像机
        roadGate: 0, // 道闸
        access: 0 // 门禁
      },
      carCount = {
        registeredCount: 0,
        unRegisteredCount: 0
      };
    peopleRes &&
      peopleRes.forEach(v => {
        Object.keys(peopleCount).forEach(item => {
          peopleCount[item] += v[item];
        });
      });
    deviceRes &&
      deviceRes.forEach(v => {
        v.deviceTypeCount.forEach(item => {
          if (DEVICE.includes(item.deviceType)) {
            deviceCount.camera += +item.count;
            return;
          }
          if (ACCESS.includes(item.deviceType)) {
            deviceCount.access += +item.count;
            return;
          }
          if (ROADGATE.includes(item.deviceType)) {
            deviceCount.roadGate += +item.count;
            return;
          }
        });
      });
    carCount = carRes;
    const FrequenCount = freRes ? freRes.data : {};
    const numCount = numRes ? numRes.data : {};
    this.setState({
      peopleCount,
      deviceCount,
      carCount,
      FrequenCount,
      numCount,
      housCount: houseRes ? houseRes : {}
    });
  };

  getCommunityList = async () => {
    let option = {
      containSuborganization: true,
      keywords: '',
      limit: 1000,
      offset: 0
    };
    const listRes = await Service.community.queryVillages(option);
    return listRes.data.list || [];
  };

  initMap = map => {
    this.cloudMap = map;
  };

  fullScreenChange = fullScreenState => {
    if (fullScreenState) {
      this.setState({ fullScreenState: true });
      this.setmapStyle('darkblue');
    } else {
      this.setState({ fullScreenState: false });
      this.setmapStyle('normal');
    }
  };

  setmapStyle = value => {
    this.cloudMap && this.cloudMap.current.props.mapMethods.setMapStyle('amap://styles/' + value);
  };

  mapReset = () => {
    this.cloudMap && this.cloudMap.current.restMap();
  };

  /**
   * @desc 点击社区获取单个社区详情
   */
  clickCommunity = id => {
    this.setState({
      infoWindowVisible: false,
    })
    if (Array.isArray(id)) {
      this.mapReset();
      this.setState({
        chosevillage: 'all'
      });
    } else {
      this.setState({
        chosevillage: id
      });
    }
    this.initNumbers(id);
    if (this.mapRef.current.jumpCommunity) {
      this.mapRef.current.jumpCommunity(id);
    } else {
      this.mapRef.current.wrappedInstance.jumpCommunity(id);
    }
  };

  openModal = async (valigeInfo, center) => {
    // 关闭视频
    try {
      this.mapRef.current.wrappedInstance.mapViewRef.current.wrappedInstance.closeVideo();
    } catch (error) {
      console.error('视频关闭失败')
    }
    await this.clickCommunity(valigeInfo.id);
    this.setState({
      infoWindowVisible: true,
      infoWindowValigeInfo: valigeInfo,
      infoWindowCenter: [center.lng, center.lat]
    });
  };

  infoWindowContent = () => {
    const { infoWindowValigeInfo = {}, peopleCount = {}, housCount = {} } = this.state;
    const { villageName, villageUrl, address, swBuildingUrl } = infoWindowValigeInfo;
    let count = 0;
    Object.values(peopleCount).forEach(v => count += v);
    return (
      <div className="cloud-info-window">
        <div className="close-btn" onClick={this.closeInfoWindow}>
          <IconFont type="icon-S_Photo_MarkNo" theme="outlined" />
        </div>
        <div className="window-content">
          <div className="cloud-info-left">
            {villageUrl ? <ImageView src={villageUrl} hasErrorImageStyle={false} /> : <IconFont style={{ fontSize: '80px', color: '#D8DCE3' }} type={'icon-M_AID_Community'} theme="outlined" />}
          </div>
          <div className="cloud-info-right">
            <div className="right-title">{villageName}</div>
            <div className="right-span">
              <div className="left">
                <IconFont type="icon-L_Place_Hotel" theme="outlined" />
                居民楼
              </div>
              <div className="right">
                <span className="right-yellow">{housCount.unitCount ? housCount.unitCount.toLocaleString() : 0}</span>栋
              </div>
            </div>
            <div className="right-span">
              <div className="left">
                <IconFont type="icon-M_Bar_People" theme="outlined" />
                居民
              </div>
              <div className="right">
                <span className="right-yellow">{ count ? count.toLocaleString() : 0 }</span>人
              </div>
            </div>
            {swBuildingUrl && <div className="right-button" onClick={() => this.go3DMap(infoWindowValigeInfo)}>
            <IconFont type="icon-S_Photo_View3D" theme="outlined" />三维地图
            </div> }
          </div>
        </div>
        <div className="window-footer">
          <IconFont type="icon-M_Bar_Add" theme="outlined" /> {address}
        </div>
      </div>
    );
  };

  closeInfoWindow = async () => {
    await this.infoWindow && this.infoWindow.close();
    await this.setState({
      infoWindowVisible: false,
    })
  }
  go3DMap = parms => {
      const token = Utils.getCache('token', 'session');
      const url = `${window.BSConfig.map3dUrl}?communityId=${parms.id}&token=${token}`;
      window.open(url);
  }
  /**
   * @desc 定时调用接口刷新右侧列表
   */
  setTimeQueryList = active => {
    const { chosevillage, list } = this.state;
    let villageIds = this.queryVillage(chosevillage === 'all' ? list : chosevillage);
    // 未分配小区时不调用接口（不然接口报错）
    if (!villageIds.length) {
      return Promise.resolve();
    }
    const option = {
      // 总量
      villageIds,
      days: -1
    };
    const optionToday = {
      // 当天
      villageIds,
      days: 1
    };
    const optionAcc = {
      villageIds,
      offset: 0,
      limit: 6
    };
    let queryList;
    switch (active) {
      case 0:
        queryList = [
          Utils.catchPromise(Service.community.queryFaces(optionAcc)),
          Utils.catchPromise(Service.community.countFaces(option)),
          Utils.catchPromise(Service.community.countFaces(optionToday))
        ];
        break;
      case 1:
        queryList = [
          Utils.catchPromise(Service.community.queryBodies(optionAcc)),
          Utils.catchPromise(Service.community.countBodies(option)),
          Utils.catchPromise(Service.community.countBodies(optionToday))
        ];
        break;
      case 2:
        queryList = [Service.community.queryCaptureVehiclesRecords(optionAcc)];
        break;
      case 3:
        queryList = [
          Utils.catchPromise(Service.community.queryAccessRecordsForCommunity(optionAcc)),
          Utils.catchPromise(Service.community.countAccessRecords({ villageIds })),
          Utils.catchPromise(Service.community.countAccessRecords({ villageIds, startTime: moment(moment().format('YYYY MM DD')).valueOf() }))
        ];
        break;
      default:
        break;
    }

    return Promise.all(queryList).then(res => {
      let list = [],
        tabsTotal = { total: 0, totalToday: 0 };
      if (active === 0 || active === 1) {
        const [listRes = {}, totalRes = {}, todayRes = {}] = res;
        list = Array.isArray(listRes.data) ? listRes.data : [];
        tabsTotal.total = totalRes.count || 0;
        tabsTotal.totalToday = todayRes.count || 0;
      }

      if (active === 2) {
        const [dataList] = res;
        list = dataList.list || [];
        tabsTotal.total = dataList.totalDateNum || 0;
        tabsTotal.totalToday = dataList.currentDateNum || 0;
      }

      if (active === 3) {
        const [listRes = {}, totalRes = {}, todayRes = {}] = res;
        list = listRes.data.list || [];
        tabsTotal.total = totalRes.count || 0;
        tabsTotal.totalToday = todayRes.count || 0;
      }

      this.setState({ tabsList: list, tabsTotal });
    });
  };

  renderCustomInfoWindow = () => {
    const { infoWindowVisible, infoWindowCenter } = this.state;
    const content = infoWindowVisible ? this.infoWindowContent() : null;
    return (
      content 
        ? <InfoWindow
            init={this.infoWindowInit}
            visible={infoWindowVisible}
            center={infoWindowCenter}
            content={content}
            notMove={true}
          />
        : null
    )
  }

  render() {
    const {
      deviceList,
      fullScreenState,
      list,
      peopleCount,
      deviceCount,
      FrequenCount,
      carCount,
      numCount,
      housCount,
      loading,
      tabsList,
      tabsTotal,
      infoWindowVisible,
      infoWindowCenter,
      chosevillage
    } = this.state;
    // const content = infoWindowVisible ? this.infoWindowContent() : null;
    return (
      <div className={`cloud-community-overview ${fullScreenState ? 'cloud-theme-2' : 'cloud-theme-1'} `} ref={this.cloudRef}>
        <Title total={list.length} />
        <Select data={list} clickCommunity={this.clickCommunity} value={chosevillage} />
        <CommunityMap
          points={deviceList}
          init={this.initMap}
          ref={this.mapRef}
          villages={list}
          openModal={this.openModal}
          customInfoWindow={this.renderCustomInfoWindow()}
          closeCustomInfoWindow={this.closeInfoWindow}
        >
          <CloudTools
            fullStatus={fullScreenState}
            fullEvent={this.checkOutScreen}
            fullScreenChange={this.fullScreenChange}
            fullDom={this.cloudRef.current}
            mapReset={this.mapReset}
          />
        </CommunityMap>
        <LeftEcharts peopleCount={peopleCount} deviceCount={deviceCount} carCount={carCount} housCount={housCount} />
        <BottomEcharts fullScreenState={fullScreenState} numCount={numCount} FrequenCount={FrequenCount} />
        {!loading && (
          <CloudTabs
            // setTimeQueryList={this.setTimeQueryList}
            // data={tabsList}
            // total={tabsTotal} 
            getVillageInfo={this.getVillageInfo}
          />
        )}
      </div>
    );
  }
  getVillageInfo = () => {
    const { chosevillage, list } = this.state;
    let villageIds = this.queryVillage(chosevillage === 'all' ? list : chosevillage);
    return villageIds
  }
}

export default CloudCommunityOverview;
