import React, { Component } from 'react';
import moment from 'moment';

const EchartsReact = Loader.loadBaseComponent('EchartsReact');

class PedsetrianFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      option: null
    };
  }

  componentDidMount() {
    const { data = {} } = this.props;
    this.getOtionTem(data);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data || nextProps.fullScreenState !== this.props.fullScreenState) {
      this.getOtionTem(nextProps.data, nextProps.fullScreenState);
    }
  }

  getOtionTem(parms = {}, full = false) {
    const avgCount = parms.countAvgPerson || [],
      count = parms.countPerson || [];
    const now = moment(
      moment()
        .subtract(31, 'd')
        .format('YYYY-MM-DD')
    ).valueOf();
    let dataCount = count.filter(v => moment(v.date).valueOf() > now);
    const avgC = [],
      arr = [],
      xAxisData = [];
    for (let i = 0; i < 31; i++) {
      const date = moment()
        .subtract(30 - i, 'd')
        .format('YYYY-MM-DD');
      const tdate = moment()
        .subtract(30 - i, 'd')
        .format('MM-DD');
      avgC.push({ date, tdate, total: 0 });
    }
    avgC.forEach(v => {
      const FIND = dataCount.find(item => item.date === v.date);
      if (FIND) {
        v.total = FIND.total;
      }
      arr.push(v.total);
      xAxisData.push(v.tdate);
    });
    let series = [
      {
        name: '当天',
        type: 'bar',
        barWidth: 8,
        lineStyle: {
          color: '#ffaa00'
        },
        itemStyle: {
          normal: {
            color: '#ffaa00',
            borderWidth: 1
          }
        },
        data: arr
      }
    ];
    let option = {
      color: ['#FFAA00'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(255,136,0,0.20)'
          }
        },
        confine: true
      },
      dataZoom: [
        {
          type: 'inside',
          throttle: 50
        }
      ],
      grid: {
        left: 60,
        right: 40,
        bottom: 30,
        top: 35
      },
      xAxis: {
        type: 'category',
        name: '天',
        splitNumber: 24,
        boundaryGap: ['20%', '20%'],
        axisTick: true,
        splitLine: {
          show: true,
          interval: 0,
          lineStyle: {
            color: !full ? '#fff' : '#212d44',
            width: 1
          }
        },
        axisLabel: {
          color: '#666',
          fontSize: 12,
          fontFamily: 'Microsoft YaHei'
        },
        axisLine: {
          show: false
        },
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        axisTick: false,
        minInterval: 1, //最小间隔
        name: '次',
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#666',
          fontSize: 12,
          fontFamily: 'Microsoft YaHei'
        },
        splitLine: {
          show: false
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: !full ? ['#F3F6F9', '#fff'] : ['#1d2336', '#212d44']
          }
        }
      },
      series: series
    };
    this.setState({
      option
    });
  }

  render() {
    const { option } = this.state;
    return <div className="flow-content">{option && <EchartsReact option={option} style={{ width: '100%', height: '200px' }} />}</div>;
  }
}

export default PedsetrianFlow;
