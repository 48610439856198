import React, { Component } from 'react';
import '../style/leftEcharts.less';

const baseData = [
  {
    title: '社区人员',
    icon: 'icon-M_AID_Registration',
    colors: ['rgba(255, 136,0,1)', 'rgba(255, 170, 0, 1)', 'rgba(167,174,188,1)', 'rgba(216,220,227,1)'],
    data: [
      { value: 0, label: '常住人员', desc: 'permanentCount' },
      { value: 0, label: '长期未出现人员', desc: 'disappearCount' },
      { value: 0, label: '频繁出现人员', desc: 'frequentlyCount' },
      { value: 0, label: '偶尔出现人员', desc: 'occasionallyCount' }
    ],
    total: 0
  },
  {
    title: '社区安防设施',
    icon: 'icon-M_AID_Access',
    colors: ['rgba(76, 196,248,1)', 'rgba(167,174,188,1)', 'rgba(216,220,227,1)'],
    data: [{ value: 0, label: '摄像机', desc: 'camera' }, { value: 0, label: '道闸', desc: 'roadGate' }, { value: 0, label: '门禁', desc: 'access' }],
    total: 0
  },
  {
    title: '社区车辆',
    icon: 'icon-M_AID_Motor',
    colors: ['rgba(37, 220,155,1)', 'rgba(216,220,227,1)'],
    data: [{ value: 0, label: '小区车辆', desc: 'registeredCount' }, { value: 0, label: '临时车辆', desc: 'unRegisteredCount' }],
    total: 0
  },
];

const CloudCard = Loader.loadBusinessComponent('Card', 'CloudCard');
// const CloudResource = Loader.loadBusinessComponent('Statistics', 'CloudResource');
const IconTitle = Loader.loadBaseComponent('IconTitle');
const IconFont = Loader.loadBaseComponent('IconFont');
class LeftEcharts extends Component {
  getData = () => {
    const data = JSON.parse(JSON.stringify(baseData));
    const { peopleCount = {}, deviceCount = {}, carCount = {} } = this.props;
    const count = [peopleCount, deviceCount];
    count.forEach((v, index) => {
      data[index].data.forEach(item => {
        item.value = v[item.desc] || 0;
        data[index].total += item.value;
      });
    });
    data[2].total = carCount.total || 0;
    data[2].data[0].value = carCount.registeredCount || 0;
    data[2].data[1].value = carCount.unRegisteredCount || 0;
    return data;
  };
  render() {
    const { housCount = {} } = this.props;
    const { buildingCount = 0, houseCount = 0, unitCount = 0 } = housCount;
    return (
      <div className="cloud-echarts">
        {this.getData().map(v => (
          <CloudCard title={v.title} icon={v.icon} colors={v.colors} data={v.data} total={v.total} />
        ))}
        <IconTitle title="社区房屋" icon="icon-L_Place_Community" className="cloud-resource-house">
          <div className="house-content">
            <div className='house-box'>
              <div className="left-house">
                <IconFont type="icon-M_AID_House" />房屋
              </div>
              <div className="right-house">{houseCount ? (+houseCount).toLocaleString(): 0}</div>
            </div>
            <div className='house-box'>
              <div className="left-house">
                <IconFont type="icon-S_Place_Hotel" />单元
              </div>
              <div className="right-house">{unitCount ? (+unitCount).toLocaleString(): 0}</div>
            </div>
            <div className='house-box'>
              <div className="left-house">
                <IconFont type="icon-M_AID_Community" />楼栋
              </div>
              <div className="right-house">{buildingCount ? (+buildingCount).toLocaleString(): 0}</div>
            </div>
          </div>
        </IconTitle>
      </div>
    );
  }
}

export default LeftEcharts;
