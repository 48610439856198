import React from 'react';
import * as _ from 'lodash';

export default ({ total = 0, title = '智慧云社区' }) => (
  <React.Fragment>
  <div className="header">
    <p title={title}>{title}</p>
  </div>
  <div className="device-total-view">
    <p>小区总数</p>
    <div className="count font-resource-normal">
      <div className="line" />
      {_.split(_.toString(total), '').map((v, k) => <span key={k}>{v}</span>)}
    </div>
  </div>
</React.Fragment>
)