import React, { Component } from 'react';
import { Tabs } from 'antd';
import PedsetrianFlow from './pedestrianFlow';

import '../../style/bottomEchart.less';

const IconFont = Loader.loadBaseComponent('IconFont');
const { TabPane } = Tabs;

class BottomEcharts extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { fullScreenState, numCount, FrequenCount } = this.props;
    return (
      <Tabs defaultActiveKey="1" onChange={this.tabsChange} className="cloud-bottom-echart">
        <TabPane
          tab={
            <span className="cloud-echart-span">
              <IconFont type="icon-L_AID_People" />
              人流量分布规律
            </span>
          }
          key="1">
          <PedsetrianFlow fullScreenState={fullScreenState} data={numCount} />
        </TabPane>
        <TabPane
          tab={
            <span className="cloud-echart-span">
              <IconFont type="icon-L_AID_NotAppearing" />
              人员出入规律
            </span>
          }
          key="2">
          <PedsetrianFlow fullScreenState={fullScreenState} data={FrequenCount} />
        </TabPane>
      </Tabs>
    );
  }
}

export default BottomEcharts;
